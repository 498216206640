.TaxVictimPreinformation {
    display: flex;
    flex-direction: column; /* Устанавливаем направление в столбец */
    justify-content: center; /* Центрирование по вертикали */
    align-items: center; /* Центрирование по горизонтали */
    height: 10vh; /* Максимальная высота контейнера (по высоте окна) */
}

.TaxVictimPreinformation a {
    text-decoration: none; /* Убираем подчеркивание ссылки */
}

.section-wrapper {
    display: block;
    max-width: 800px;
    margin-bottom: 30px;
}

.section-image {
    max-width: 800px;
    margin-bottom: 10px;
}