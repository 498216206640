.PCS_link {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center; /* Для блочных элементов */
}

.PCS_link .OpenPancakeSwapButton {
    margin: 0 auto; /* Или установите нужные отступы */

}

.section-wrapper {
    display: block;
    max-width: 800px;
    margin-bottom: 30px;
}

.section-image {
    max-width: 800px;
    margin-bottom: 10px;
}