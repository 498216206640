.sidebar-container {
  padding: 0 0 0 20px;
  min-height: 100%;
  min-width: 190px;
}

.sidebar-item {
  margin-bottom: 10px;
  font-size: 18px;
}

.sidebar-item a {
  padding: 10px;
  border-radius: 4px;
  text-decoration: none;
  color: black;
  width: 100%;
  height: 100%;
  display: block;
}

.sidebar-item a.active {
  background-color: #726e6e;
  color: white;
}
