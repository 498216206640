.spoiler {
  width: 60%; /* Ширина блоков (можете настроить по вашему выбору) */
  text-align: center; /* Центрирование текста внутри блоков */
  padding: 10%; /* Поля для блоков (по желанию) */
  margin: 10%; /* Внешние отступы между блоками (по желанию) */
}

.accordion-header .accordion-button {
  background-color: rgb(207, 226, 255) !important;
}
