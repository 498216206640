.section-wrapper {
  display: block;
  max-width: 800px;
  margin-bottom: 30px;
}

.section-image {
  max-width: 800px;
  margin-bottom: 10px;
}

.blacklist-container {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.PancakeBlock {
  display: flex;
  flex-direction: column;
  align-items: center; /* Центрирование по горизонтали */
  justify-content: center; /* Центрирование по вертикали */
  text-align: center; /* Центрирование текста по горизонтали */
  margin: 20px 0; /* Пространство вокруг блока */
}

.OpenPancakeSwapButton {
  background-color: #2196f3; /* Основной цвет кнопки (синий) */
  color: #ffffff; /* Цвет текста (белый) */
  padding: 15px 30px; /* Отступы вокруг текста */
  border: none; /* Убираем границу */
  border-radius: 4px; /* Скругляем углы */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Добавляем тень */
  cursor: pointer; /* Курсор при наведении */
  transition: background-color 0.3s; /* Анимация при наведении */
  font-size: 16px; /* Размер текста */
}

.OpenPancakeSwapButton:hover {
  background-color: #0e88b3; /* Новый цвет при наведении (более темный синий) */
}

.MintBlock {
  display: flex;
  flex-direction: column;
  align-items: center; /* Центрирование по горизонтали */
  text-align: center; /* Центрирование текста по горизонтали */
  margin: 20px 0; /* Пространство вокруг блока */
}

.MintButton {
  background-color: #2196f3; /* Основной цвет кнопки (синий) */
  color: #ffffff; /* Цвет текста (белый) */
  padding: 15px 30px; /* Отступы вокруг текста */
  border: none; /* Убираем границу */
  border-radius: 4px; /* Скругляем углы */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Добавляем тень */
  cursor: pointer; /* Курсор при наведении */
  transition: background-color 0.3s; /* Анимация при наведении */
  font-size: 16px; /* Размер текста */
}

.MintButton:hover {
  background-color: #0e88b3; /* Новый цвет при наведении (более темный синий) */
}

.DeployBlock {
  display: flex;
  flex-direction: column;
  align-items: center; /* Центрирование по горизонтали */
  text-align: center; /* Центрирование текста по горизонтали */
  margin: 20px 0; /* Пространство вокруг блока */
  font-size: 20px; /* Размер текста 20px */
}

.DeployButton {
  padding: 10px 20px; /* Отступы кнопки */
  background-color: #0077b5; /* Цвет фона кнопки (например, синий) */
  color: #ffffff; /* Цвет текста (белый) */
  border: none; /* Убираем границу кнопки */
  border-radius: 5px; /* Скругляем углы кнопки */
  cursor: pointer; /* Курсор при наведении на кнопку */
  transition: background-color 0.3s; /* Анимация изменения цвета фона при наведении */
}

.DeployButton:hover {
  background-color: #005599; /* Новый цвет фона при наведении (например, темно-синий) */
}

.PancakeTradePage1 {
  display: flex;
  flex-direction: column;
  align-items: center; /* Центрирование по горизонтали */
  text-align: center; /* Центрирование текста по горизонтали */
  margin: 20px 0; /* Пространство вокруг контейнера */
  object-fit: cover; /* Обрезаем изображение с сохранением соотношения сторон */
  margin-top: 10px; /* Добавляем отступ между изображением и текстом */
  font-size: 25px; /* Устанавливаем размер шрифта текста в 15px */
}

.PancakeTradePage2 {
  display: flex;
  flex-direction: column;
  align-items: center; /* Центрирование по горизонтали */
  text-align: center; /* Центрирование текста по горизонтали */
  margin: 20px 0; /* Пространство вокруг контейнера */
  object-fit: cover; /* Обрезаем изображение с сохранением соотношения сторон */
  margin-top: 10px; /* Добавляем отступ между изображением и текстом */
  font-size: 25px; /* Устанавливаем размер шрифта текста в 15px */
}

.PancakeTradePage3 {
  display: flex;
  flex-direction: column;
  align-items: center; /* Центрирование по горизонтали */
  text-align: center; /* Центрирование текста по горизонтали */
  margin: 20px 0; /* Пространство вокруг контейнера */
  object-fit: cover; /* Обрезаем изображение с сохранением соотношения сторон */
  margin-top: 10px; /* Добавляем отступ между изображением и текстом */
  font-size: 25px; /* Устанавливаем размер шрифта текста в 15px */
}

.PancakeTradePage4 {
  display: flex;
  flex-direction: column;
  align-items: center; /* Центрирование по горизонтали */
  text-align: center; /* Центрирование текста по горизонтали */
  margin: 20px 0; /* Пространство вокруг контейнера */
  object-fit: cover; /* Обрезаем изображение с сохранением соотношения сторон */
  margin-top: 10px; /* Добавляем отступ между изображением и текстом */
  font-size: 25px; /* Устанавливаем размер шрифта текста в 15px */
}

.PancakeTradePage5 {
  display: flex;
  flex-direction: column;
  align-items: center; /* Центрирование по горизонтали */
  text-align: center; /* Центрирование текста по горизонтали */
  margin: 20px 0; /* Пространство вокруг контейнера */
  object-fit: cover; /* Обрезаем изображение с сохранением соотношения сторон */
  margin-top: 10px; /* Добавляем отступ между изображением и текстом */
  font-size: 25px; /* Устанавливаем размер шрифта текста в 15px */
}

.PancakeTradePage6 {
  display: flex;
  flex-direction: column;
  align-items: center; /* Центрирование по горизонтали */
  text-align: center; /* Центрирование текста по горизонтали */
  margin: 20px 0; /* Пространство вокруг контейнера */
  object-fit: cover; /* Обрезаем изображение с сохранением соотношения сторон */
  margin-top: 10px; /* Добавляем отступ между изображением и текстом */
  font-size: 25px; /* Устанавливаем размер шрифта текста в 15px */
}

.PancakeTradePage7 {
  display: flex;
  flex-direction: column;
  align-items: center; /* Центрирование по горизонтали */
  text-align: center; /* Центрирование текста по горизонтали */
  margin: 20px 0; /* Пространство вокруг контейнера */
  object-fit: cover; /* Обрезаем изображение с сохранением соотношения сторон */
  margin-top: 10px; /* Добавляем отступ между изображением и текстом */
  font-size: 25px; /* Устанавливаем размер шрифта текста в 15px */
}

.PancakeTradePage8 {
  display: flex;
  flex-direction: column;
  align-items: center; /* Центрирование по горизонтали */
  text-align: center; /* Центрирование текста по горизонтали */
  margin: 20px 0; /* Пространство вокруг контейнера */
  object-fit: cover; /* Обрезаем изображение с сохранением соотношения сторон */
  margin-top: 10px; /* Добавляем отступ между изображением и текстом */
  font-size: 25px; /* Устанавливаем размер шрифта текста в 15px */
}

.imageBlock {
  width: 1200px;
  text-align: center;
  display: block;
  background-color: transparent;
  border: 1px solid transparent;
  margin-right: 10px;
  margin-bottom: 1px;
  float: left;
}

.PancakeTradePage9 {
  display: flex;
  flex-direction: column;
  align-items: center; /* Центрирование по горизонтали */
  text-align: center; /* Центрирование текста по горизонтали */
  margin: 20px 0; /* Пространство вокруг контейнера */
  object-fit: cover; /* Обрезаем изображение с сохранением соотношения сторон */
  margin-top: 10px; /* Добавляем отступ между изображением и текстом */
  font-size: 25px; /* Устанавливаем размер шрифта текста в 15px */
}

.PancakeTradePage10 {
  display: flex;
  flex-direction: column;
  align-items: center; /* Центрирование по горизонтали */
  text-align: center; /* Центрирование текста по горизонтали */
  margin: 20px 0; /* Пространство вокруг контейнера */
  object-fit: cover; /* Обрезаем изображение с сохранением соотношения сторон */
  margin-top: 10px; /* Добавляем отступ между изображением и текстом */
  font-size: 25px; /* Устанавливаем размер шрифта текста в 15px */
}

.PancakeTradePage11 {
  display: flex;
  flex-direction: column;
  align-items: center; /* Центрирование по горизонтали */
  text-align: center; /* Центрирование текста по горизонтали */
  margin: 20px 0; /* Пространство вокруг контейнера */
  object-fit: cover; /* Обрезаем изображение с сохранением соотношения сторон */
  margin-top: 10px; /* Добавляем отступ между изображением и текстом */
  font-size: 25px; /* Устанавливаем размер шрифта текста в 15px */
}

.PancakeTradePage12 {
  display: flex;
  flex-direction: column;
  align-items: center; /* Центрирование по горизонтали */
  text-align: center; /* Центрирование текста по горизонтали */
  margin: 20px 0; /* Пространство вокруг контейнера */
  object-fit: cover; /* Обрезаем изображение с сохранением соотношения сторон */
  margin-top: 10px; /* Добавляем отступ между изображением и текстом */
  font-size: 25px; /* Устанавливаем размер шрифта текста в 15px */
}

.PancakeTradePage13 {
  display: flex;
  flex-direction: column;
  align-items: center; /* Центрирование по горизонтали */
  text-align: center; /* Центрирование текста по горизонтали */
  margin: 20px 0; /* Пространство вокруг контейнера */
  object-fit: cover; /* Обрезаем изображение с сохранением соотношения сторон */
  margin-top: 10px; /* Добавляем отступ между изображением и текстом */
  font-size: 25px; /* Устанавливаем размер шрифта текста в 15px */
}

.PancakeTradePage14 {
  display: flex;
  flex-direction: column;
  align-items: center; /* Центрирование по горизонтали */
  text-align: center; /* Центрирование текста по горизонтали */
  margin: 20px 0; /* Пространство вокруг контейнера */
  object-fit: cover; /* Обрезаем изображение с сохранением соотношения сторон */
  margin-top: 10px; /* Добавляем отступ между изображением и текстом */
  font-size: 25px; /* Устанавливаем размер шрифта текста в 15px */
}

.PancakeTradePage15 {
  display: flex;
  flex-direction: column;
  align-items: center; /* Центрирование по горизонтали */
  text-align: center; /* Центрирование текста по горизонтали */
  margin: 20px 0; /* Пространство вокруг контейнера */
  object-fit: cover; /* Обрезаем изображение с сохранением соотношения сторон */
  margin-top: 10px; /* Добавляем отступ между изображением и текстом */
  font-size: 25px; /* Устанавливаем размер шрифта текста в 15px */
}

.PancakeTradePage15 img {
  border-radius: 16px;
}

.PancakeTradePage16 {
  display: flex;
  flex-direction: column;
  align-items: center; /* Центрирование по горизонтали */
  text-align: center; /* Центрирование текста по горизонтали */
  margin: 20px 0; /* Пространство вокруг контейнера */
  object-fit: cover; /* Обрезаем изображение с сохранением соотношения сторон */
  margin-top: 10px; /* Добавляем отступ между изображением и текстом */
  font-size: 25px; /* Устанавливаем размер шрифта текста в 15px */
}

.PancakeTradePage16 img {
  border-radius: 16px;
}

.PancakeTradePage17 {
  display: flex;
  flex-direction: column;
  align-items: center; /* Центрирование по горизонтали */
  text-align: center; /* Центрирование текста по горизонтали */
  margin: 20px 0; /* Пространство вокруг контейнера */
  /*object-fit: cover; !* Обрезаем изображение с сохранением соотношения сторон *!*/
  margin-top: 10px; /* Добавляем отступ между изображением и текстом */
  font-size: 25px; /* Устанавливаем размер шрифта текста в 15px */
}

.PancakeTradePage17 img {
  border-radius: 16px;
}

.PancakeTradePage18 {
  display: flex;
  flex-direction: column;
  align-items: center; /* Центрирование по горизонтали */
  text-align: center; /* Центрирование текста по горизонтали */
  margin: 20px 0; /* Пространство вокруг контейнера */
  object-fit: cover; /* Обрезаем изображение с сохранением соотношения сторон */
  margin-top: 10px; /* Добавляем отступ между изображением и текстом */
  font-size: 25px; /* Устанавливаем размер шрифта текста в 15px */
}

.PancakeTradePage18 img {
  border-radius: 16px;
  max-width: 30%;
  max-height: 30%;
}

.TextFieldButton {
  display: flex;
  flex-direction: column;
  align-items: center; /* Центрирование по горизонтали */
  text-align: center; /* Центрирование текста по горизонтали */
  margin: 20px 0; /* Пространство вокруг контейнера */
}

.TextFieldButton button {
  font-size: 25px; /* Размер текста кнопки 25px */
  padding: 10px 20px; /* Отступы кнопки */
  background-color: #ff0000; /* Цвет фона кнопки (красный) */
  color: #ffffff; /* Цвет текста (белый) */
  border: none; /* Убираем границу кнопки */
  border-radius: 5px; /* Скругляем углы кнопки */
  cursor: pointer; /* Курсор при наведении на кнопку */
  transition: background-color 0.3s; /* Анимация изменения цвета фона при наведении */
}

.TextFieldButton button:hover {
  background-color: #ff6666; /* Новый цвет фона при наведении */
}
