.navbar-nav .nav-link {
  font-size: 18px;
}

.navbar-nav .nav-link.active {
  padding-bottom: 5px;
  border-bottom: 1px solid black;
}

.navigation-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0 15px;
}
